$break-xsmall: 576px;
$break-small: 768px;
$break-medium: 992px;
$break-large: 1200px;


@mixin xs {
    @media screen and (max-width: #{$break-xsmall}) {
      @content;
    }
  }
  
  @mixin xs-height {
    @media screen and (max-height: #{$break-xsmall}){
      @content;
    }
  }
  
  @mixin sm {
    @media screen and (max-width: #{$break-small}) {
      @content;
    }
  }
  
  @mixin md {
    @media screen and (max-width: #{$break-medium}) {
      @content;
    }
  }
  
  @mixin lg {
    @media screen and (max-width: #{$break-large}) {
      @content;
    }
  }

 
  .home-image{
    height: 180px;
    object-fit: contain;
    @include sm {
      height: 75px;
      object-fit: contain;
     }
  
  }

  .ant-card-body{
    padding: 20px;
    @include sm {
      padding: 8px;
     }
  }
  .home-card { 
    flex: 1 0 auto;
    width: 200px;
    @include sm {
      width: 150px;
      height: 160px;
    flex: 1 0 auto;
     }
  }
  .app-header-links-menu-logo > .logo {
    flex: 2;
    margin-left: 5.25em;
    @include sm {
      margin-left: -1.75em;
     }
  }
.app-header {
  @include sm {
   }
}
.city-tour > .app-container > .app-header{
  height: auto;
  @include sm {
    height: auto;
   }
}
  .app-header-links-menu {
    height: 72%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: #11998e; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #3c614a,
      #11998e
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #3c614a,
      #11998e
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    @include sm {
      height: 72%;
     }
  }
  .app-footer-copyright-link {
    color: rgb(203, 60, 111);
    font-size: 0.8em;
  }
  .app-home-card-title {
    padding: 0.0em 0.83em;
    font-weight: 700;
    font-size: 1.1em;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
  }
  .app-home-card {
    height: auto;
    background: #fff;
    @include sm {
      height: 183px;
      background: #fff;
      max-height: 34%;
     }
  }

  .home-card > .ant-card-cover {
    height: 65%;
    width: 100%;
  @include sm {
    width: 150px;
    height: 80px;
   }
}
.app-home-card-container {
  height: 250px;
  display: flex;
  flex: 1 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
   @include sm {
    height: 162px;
    display: flex;
    flex: 1 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
   }
}

.ant-card-meta-description {
  height: 3em !important;
  padding-top: 0.3em;
  @include sm{
    font-size: 0.8em;
    padding-top: 0.3em;
    height: 3em !important;
  }
}
.ant-card-meta{
  margin-bottom: 2px
}

.carousel-control-next-icon, .carousel-control-prev-icon{
  width: 60px;
  height: 60px;
  @include sm{
    width: 25px;
    height: 25px;
  }
}
.app-footer {
  background: #F5F2F2;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
/* Family Card */
.app-family-card {
  background: #635241;
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;
  height: 300px;
  max-height: 70%;
  cursor: pointer;
  @include sm{
    background: #635241;
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;
  height: 185px;
  max-height: 34%;
  }
}
.std-modal {
width: 520px !important;
position: absolute;
left: 0px;
right: 0px;
margin: auto !important;
}
.ant-input:hover, .ant-input:focus{
  color: #000;
}
.about-us-team-card {
  height:50.2%;
  background: #fff;
    box-shadow: 0px 20px 12px 22px #ccc;
  @include sm{
    height: 29.2%;
    background: #fff;
    box-shadow: 0px 20px 12px 22px #ccc;
  }
}
.logo-icon{
  object-fit: contain;
}
.service-container {
  height: 33.34%;
  display: flex;
  box-shadow: 0 0px 5px 0px #ccc;
  
 
}
.search-home{
  background: transparent !important;
}
.ant-modal-confirm-btns{
  display: flex;
  justify-content: center;
}
.buddy-form{
  margin-top: 2em;
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.background-slider-aboutus{
  height: 100%;
  object-fit: cover;
  width: 100%;
  opacity: 1;
  box-shadow: inset 0px 0px 341px 70px #021121;
}
.about-us-title{
    color: #fff;
    font-size: 2em;
    text-align:center;
    @include sm{
      text-align:center;
      font-size: 1em;
     
    }
}
.about-us-carousal{
  height: 300px;
  @include sm{
    height: 200px;
   
  }
}

/* Home Card */
.mr10{
  margin-top:2%;
  @include sm{
    margin-top:3%;
   
  }
}
.review-comment{
  @include sm{
   width: 100%;
   
  }
}
.app-administration-services-menu {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  padding: 0 0.5em;
  margin: 0 auto;
  overflow: scroll;
  justify-content: center;
  color: #fff;
  @include sm{
    justify-content: start;
  }
}
.about-row{
  height: 100%;
}
.about-us-card-desc{
  color: #fff;
  float: left;
  font-size: 1.5em;
  @include sm{
    color: #fff;
    float: left;
    font-size: .7em;
  }
}
.ant-tabs-nav-container{
  
  @include sm{
    font-size: 0.6em;
  text-align: center;
  }
}
.user-profile-card{
  background: #fff;
}
.user-profile-meta{
  background: rgb(153, 54, 101);
}
.user-profile-link{
  color: #fff;
}
.user-profile-desc{
  color: #fff;
}
.our-team{
  font-size: 1.25em;
  font-weight: 600;
  color: rgb(153, 153, 153);
  margin:.7em
}
.social-button{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.share-icon{
  position: absolute;
  right: 0px;
  top: 5.4em;
  font-size: 1.1em;
  cursor: pointer;
}
.share-icon1{
  position: absolute;
  right: 0px;
  top: 5em;
  font-size: 1.3em;
}

.socialicon{
  width: 100%;
  margin-bottom: .8em;
}
.facebook{
 
  color: #3B5998;
}
.twitter{
  color: #55ACEE;
}
.family-card-title{
  padding: 0.0em 0.83em;
  font-weight: 700;
  font-size: 1.1em !important;
  color:rgba(0, 0, 0, 0.65)
}
 .mapStyles  {
  width: '100%';
  height: '100%',
}
.slick-slider{
  height: 200px;
}
.referance{
  font-weight: bold;
  color: #4faf77;
  padding-top: 1.5em;
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-bottom: 0em;
}
.review-avatar > .ant-avatar{
  height: 50px !important;
  width: 50px !important;
}
.service-details{
  margin-top: 2.1em;
}
.app-footer-copyright-text{
  font-size: 0.8em;
}
.find-out-more{
  cursor: pointer;
    color: rgb(153, 51, 102) !important;
    font-size: .9em;
    font-weight: bold;
}
.p-left-10{
  padding-left: 10px;
}
.select-subject > .ant-select-selection{
  border-color: none;
  box-shadow: none;
}
.select-subject > .ant-select-selection > .ant-select-arrow{
  color: #000;
}
.select-subject > .ant-select-selection >.ant-select-selection__rendered > .ant-select-selection-selected-value{
width: 100%;
text-align: center;
}

.contact-textarea{
  padding-left: 1em;
    padding-top: 1em;
}
.margin-t-1em{
  margin-top: 1em;
}
.footer-policy{
  border-right: 1px solid #000;
  padding-right: .6em;
  font-size: 12px;
  padding-left: .4em;
  padding-right: .4em;
}
.color-div{
  margin-left: 1em;
  width: 20px;
  height: 18px;
  z-index: 1;
  border: 1px solid;
  cursor: pointer;
  margin-bottom: 0px;
}
.sketch-picker{
  position: absolute;
    z-index: 1;
    right: -18px;
    left: 0;
    margin: auto;
    margin-top: 20px;
    bottom: 100px;
}
.citi-table > thead > tr > th{
  border-top:0px;
  border-bottom: 0px;
}
.citi-table > tbody > tr > td{
  border-top:0px;
  border-bottom: 0px;
}
.city-head{
  background: #a0a0a0;
}
.admin-tabs > .ant-tabs-bar{
margin: 0px;
}
.table-shadow{
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
}
.tab-header{
text-align: right;
padding: 1em
}
.add-btn{
  background: #11998e;
  color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
  border-radius: 0px;
  border: none;
}
.p-left-1em{
  padding-left: 1em;
  border-radius: 0px;
  border: 1px solid #efefef;
  height: 45px;
}
.save-btn{
  background: rgb(142, 48, 90);
  color: #fff;
}
.image-upload > input {
  display: none;
}
.dashboard-card{
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
  background: #fff;
  color: #000;
  margin-bottom: 1em;
}
.choose-image{
  background: #fff;
  color: #000;
  padding: .6em;
  border: 1px dashed #1890ff;
  cursor: pointer;
  box-shadow: 0 2px 8px #f0f1f2;
}
.d-none{
  display: none;
}
.ant-drawer-content-wrapper{
  width: 300px !important;
  @include sm{
    width: 300px !important;
  }
}
.ant-drawer-header{
  background: #11998e;
}
.ant-drawer-title{
  color: #fff;
}
.capitalize{
  text-transform: capitalize;
}
.badge-count> .ant-badge-count{
background: #fff;
 color: #999;
box-shadow: 0 0 0 1px #d9d9d9 inset
}
.badge-lang> .ant-badge-count{
  background: #26797d;
  color: #fff;
  box-shadow: 0 0 0 1px #d9d9d9 inset
  }
.elipsis{
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subproductsModal  {
width: 720px !important;
}
.subproductsModal > .ant-modal-content{
  background: #fff;
}
.colorCode-box{
  margin-left: 1em;
}
.blue-color{
  color: blue !important;
}
.grey-color{
  color: #000 !important;
}
.row-align{
  display: flex;
    align-items: center;
}
.search-box{
  width: 200px;
  margin-left: 1em;
  margin-right: 1em;
  padding: 1em;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
    border: none;
    border-bottom: 1px solid #1b8b7e;
    border-radius: 0px;
}
.dashboardbox-icon{
  font-size: 4em;
    text-align: center;
    color: red;
}
.dashboard-card > .ant-card-body{
  text-align: center;
}
.dashboard-card > .ant-card-body > .ant-card-meta > .ant-card-meta-detail > .ant-card-meta-title > {
  font-size: 3em;
  height: auto;
}

.dashboard-card > .ant-card-body > .ant-card-meta > .ant-card-meta-detail > .ant-card-meta-description{
  font-size: 4em;
  color: #736c6c;
  height: auto !important;
}

.custom-loader{
    border: 4px solid green;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    margin: auto;

}  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .buddies-title{
  font-size: 1.5em;
  margin-top:1em;
  margin-bottom: 1em;
  }
  .our-buddies-list{
    height: 15.5em;
  }
  .our-buddies-list > .ant-card-cover > .ant-avatar{
    width: 126px;
    height: 126px;
    margin-top: -40px
  }
  .buddy-profile > .ant-card-cover > .ant-avatar{
    width: 190px;
    height: 190px;
    margin-top: -56px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .our-buddies-list > .ant-card-cover > .ant-avatar > img{
    object-fit: contain;

  }
  .buddy-profile > .ant-card-cover > .ant-avatar > img{
    object-fit: contain;

  }
  .our-buddies-list > .ant-card-cover {
    display: flex;
    justify-content: center;
  }
  .buddy-profile> .ant-card-cover {
    display: flex;
    justify-content: center;
  }
  .our-buddies-list{
    text-align: center;
  }
  .our-buddies-list > .ant-card-body > .ant-card-meta > .ant-card-meta-detail > .ant-card-meta-title{
    color: rgb(51, 204, 153);
    font-weight: bold;
  }
  .language{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .lang-code{
    border-right: 1px solid;
    padding:.3em
  }
  .our-buddies-list > .ant-card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .member-buddy{
    margin-bottom: 3em;
    margin-right: 1em;
  }
  .email-title{
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .main-logo{
    height: 55px;
    @include sm{
     height: 40px; 
    }
  }
  .logo-position{
    color: rgb(255, 255, 255);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: .7em;
    width: 20%;
    @include sm{
      top: .1em;
      width: 50%;
     }
    
  }
  .card-box{
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
    border: none;
  }
  .profile-img{
    height: 230px;
    object-fit: cover;
    width: 200px;
  }
  .p-title{
    color:rgb(51, 204, 153);
    font-weight:bold
  }
  .view-btn{
   color: #7cd4a3 !important;
   border-color: #7cd4a3 !important;
   border-radius: 0px;
   width: 70%;
   margin-top: 1em;
   position: absolute;
   left: 0;
   right: 0;
   margin: auto;
   bottom: 1em;
  }
  .view-btn:hover{
    background: #7cd4a3 !important;
    color: #fff !important;
  }
  .connect-btn{
    color: #fff !important;
    border-color: #7cd4a3 !important;
    border-radius: 0px;
   }
   .lan-tag-style{
     background: #7cd4a3;
     color: #fff;
   }
   .cust-loader-parent{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    height: 100%;
    background: #000;
    opacity: .5;
    position: fixed;
    z-index: 1000;
   }

   .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .modal-loader{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    height: 100%;
    background: #c5c5c5;
    opacity: .5;
  }
  .modal-spinner{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
  }
  .ant-select-search__field{
    background: transparent !important;
  }
  .auto-search > .ant-select-selection{
    background: transparent !important;
  }
  .ant-select-search__field__wrap > .ant-input{
    color: #fff !important;
  }
  .auto-search > .ant-select-selection > .ant-select-selection__rendered > .ant-select-selection__placeholder{
margin-left: 35px !important;
  }
  .custom-spinner{
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    top: 0;
    text-align: center;
    background: #000;
    opacity: .5;
  }
  .customer-antd-spinner{
    top: 50%;
    left: 0;
    right: 0;
    position: absolute;
    margin: auto;

  }
.custom-spinner-review{
  position: fixed;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    top: 0;
    text-align: center;
    background: #000;
    opacity: .5;
    z-index: 1;
}
.undo-bookmark{
  font-size: 1.4em;
  color: #32705c;
}
.checklist-list{
    padding: .2em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
    margin-bottom: 1em;
    display: flex;
    align-items: center;
}
.list-icon{
    justify-content: space-around;
    align-items: center;
    font-size: 10px;
}
.col-div{
  display: flex;
  flex: auto;
}
.info-modal{
  width: 400px !important;
}
.info-modal > .ant-modal-content > .ant-modal-header{
  background: #3c614a !important;
}
.info-modal> .ant-modal-content > .ant-modal-header > .ant-modal-title{
  color: #fff !important;
}
.info-modal > .ant-modal-content{
  background: #fff;
}
.green{
  color: rgb(51, 204, 153)
}
.black{
  color: #000;
}
.take-me-button{
  background: #84286a;
  border-color: #84286a;
}
.blurry-div{
  background: #fff;
  height: 26px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  box-shadow: 0 4px 10px 0 #fff, 0 -10px 26px #fff;
}
.package-div{
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 15%, #ffffff 65%, #ffffff);
}
.font-para{
  color: rgb(89, 89, 89);
  font-weight: 100;
}
.next-icon{
  float: right;
    font-size: 1.1em;
    font-weight: bold;
    color: #fff;
}
.prev-icon{
  float: left;
    font-size: 1.1em;
    font-weight: bold;
    color: #fff;
}
.next{
  display: none;
  @include sm{
    display: block;
  }
}
.prev{
  display: none;
  @include sm{
    display: block;
  }
}
.certain-category-icon > svg{
  margin-top: .3em;
  margin-left: .3em;
}
.profile-shadow{
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
  margin-bottom: 1em;

}
.lets-button{
  color: #fff;
  background: rgb(142, 48, 90)
}
.select-form > .ant-select-selection > .ant-select-selection__rendered > .ant-select-selection__placeholder{
  color: rgba(0, 0, 0, 0.65) !important;
  text-align: center !important;
  font-weight: bold;
}
.ellipsis-cl{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-27{
  width: 100%;
  @include sm{
    width: 74%;
  }
}
.social-font-icon{
  font-size: 1.5em;
}
.buddy-row{
  margin-top: 4em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.verification{
  margin-top: 20%;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19)
}
.verify-title{
  color: rgba(0,0,0,.87);
  direction: ltr;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 1em;
}
.displayNameModal > .ant-modal-content{
  background: #fff;
}
.displayNameModal{
  width: 300px !important;
}
.category-modal{
  width: 100%;
}
.displayNameModal> .ant-modal-content > .ant-modal-header{
  background: #3c614a !important;
}
.displayNameModal> .ant-modal-content > .ant-modal-header > .ant-modal-title{
  color: #fff !important;
}
.city-select-box > .ant-select-selection > .ant-select-selection__rendered > .ant-select-selection-selected-value{
  font-weight: 100;
  background: transparent;
  color: #fff;
  font-weight: bold;
}

.city-select-box > .ant-select-selection  {
background: transparent;
border: none;
}
.city-select-box > .ant-select-selection > .ant-select-selection__rendered{
  margin: 0px;
}
.city-select-box > .ant-select-selection >.ant-select-arrow > .anticon{
  color: #fff;
}
.ant-modal-confirm-confirm{
  width: 416px !important;
}
.displayNameModal > .ant-modal-content >  .ant-modal-close {
  display: none;
}
.displayNameModal{
  text-align: center;
}
.required{
  border: 1px solid red;
  padding: 10px;
}
.comment-modal > .ant-modal-content > button{
  display: none;
}
.comment-modal  > .ant-modal-content{
  background: #fff;
}
.comment-modal {
  width: 720px !important;
}
.comment-modal {
  width: 100%;
}

.comment-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title{
  color: #fff !important;
}
.property-div{
  white-space: nowrap;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-pic{
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
  border-radius: 0px;
}
.profile-section{
  display: flex;
  align-items: center;
}
.copied{
  position:absolute;
  left:-9999px
}

.info-modal-email > .ant-modal-content > button{
  display: none;
}
.info-modal-email  > .ant-modal-content{
  background: #fff;
}
.info-modal-email {
  width: 720px !important;
}
.info-modal-email {
  width: 100%;
}

.info-modal-email > .ant-modal-content > .ant-modal-header > .ant-modal-title{
  color: #fff !important;
}
.info-modal-email > .ant-modal-content > .ant-modal-body >.ant-modal-confirm-body-wrapper > .ant-modal-confirm-btns{
  float: none;
}
.anticon-info-circle{
  display: flex;
  justify-content: center;
  float: none !important;
}
.avatar-uploader >.ant-upload > .ant-upload{
  padding: 0px !important;
}

.comparemodal  > .ant-modal-content{
  background: #fff;
}
.comparemodal {
  width: 920px !important;
}
.comparemodal {
  width: 100%;
}
.comparemodal > .ant-modal-content > .ant-modal-header {
  background: #366851 !important;
}
.comparemodal > .ant-modal-content > .ant-modal-header > .ant-modal-title{
  color: #fff !important;
  text-align: center;
}
.comparemodal > .ant-modal-content > .ant-modal-body >.ant-modal-confirm-body-wrapper > .ant-modal-confirm-btns{
  float: none;
}
.comparemodal > .ant-modal-content > .ant-modal-body{
  padding:0px !important;
}
.card2 > .ant-card-head{
  text-align: center;
}
.card1 > .ant-card-head{
  text-align: center;
}
.checkbox-wrapper{
  margin-right: .5em;
  margin-top: .1em;
  float: right;

}
.checkbox-wrapper > .ant-checkbox >  .ant-checkbox-inner{
  border: 1px solid rgb(132, 52, 106);
}
.for-small-device{
  @include sm{
    position: absolute;
    top: 0;
    margin: auto;
    left: 3em;
  }
 
}
.margint-5-small{

  @include sm{
    margin-top: 5em;
  }
}
.row-small{

  @include sm{
    display: flex;
    justify-content: center;
  }
}
.card-profile > .ant-card-body > .ant-upload-picture-card-wrapper{
  display: flex;
  justify-content: center;
}
.card-profile > .ant-card-body{
  text-align: center;
}
.card-profile > .ant-card-body > .ant-upload-picture-card-wrapper > .ant-upload-select-picture-card{
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
  width: 174px;
}
.rdt_TableHeader{
  display: none !important;
}
.float-right{
  float: right;
}
.rdt_TableHeadRow{
  background: #e2dfdf !important;
  height: 30px !important;
    border: 1px solid #b7c2ce;
    min-height: 30px !important;
}
.rdt_Pagination{
  background: #e2dfdf !important;

}
.select-category-product{
  width: 20% !important;
  float: right !important;
}
.select-category-product > .ant-select-selection {
height: 33px !important;
border: none;
border-bottom: 1px solid #198F81;
border-radius: 0px;
box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}
.left-mr{
  margin-left: 1em;
}
.anticon-edit{
  margin-top: - 0.3em !important;
}
.anticon-delete{
  margin-top: - 0.3em !important;
}
.icon-btn-table{
  border-radius: 0px !important;
}
.anticon-eye{
  margin-top: - 0.3em !important;

}
.deleteButtons{
  display: flex;
    justify-content: space-evenly;
}
.react-modal{
  text-align: center;
    margin-bottom: 1em;
}
.comparison > .ant-checkbox > .ant-checkbox-inner{
  border-color: #822c53 !important;
  height: 11px !important;
  width: 11px !important;
}
.comparison-check > .ant-checkbox > .ant-checkbox-inner{
  border-color: #2295ff !important;
  height: 11px !important;
  width: 11px !important;
}
.password-reset{
  padding-left: 1em;
    font-family: monospace;
}
.note-checkbox > .ant-checkbox >  .ant-checkbox-inner{
    border-color: #2295ff !important;
  
}

// .left-map-side{
//   position: fixed;
//   z-index: 1;
//   bottom: 88px;
// }
// .right-map-side{
//   position: absolute;
//   right: 0px;
// }
.clear-icon{
  position: absolute;
    font-size: 1.3em;
    top: 6px;
    right: 27px;
    margin: auto;
    padding: 4px;
    padding-left: 10px;
    color: #373535;
    font-weight: 100;
    cursor: pointer;
    font-weight: 600;

  // border-left: 1px solid #c1bebe;
}
.timeline-Widget{
  color: #fff !important;
}
.mb-1em{
  margin-bottom: .1em;
}

.google-search-box{
  border-radius: 6px;
  border: 1px solid #b1b1b1;
  padding-left: 2em;
    // box-shadow: 0 4px 6px 0 rgba(32,33,36,0.28)
}
.blur-effect{
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.padding-1em{
  padding: 1em;
}
.faq-con{
  color: #000;
  margin-left: 10em;
  margin-right: 10em;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
  @include sm{
    margin-left: 1em;
    margin-right: 1em;;
  }
}
.cityBackgroundImage{
  width: 500px;
  float: right;
}