/* import backgroundImage */

/* Base Styles */
* {
  padding: 0;
  margin: 0;
}

body,
html {
  min-height: 575px;
  height: 100%;
}

/* App-container */

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-container {
  /* margin-top: 3.51%; */
  height: -webkit-fill-available;
  /* background: red; */
}


.app-header-links-menu-logo {
  font-size: 1.65em;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin: 0 1.67em 0 0.83em;
  line-height: 1.84em;
}

.app-header-links-menu-help-text {
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin-left: 0em;
  margin-right:  0.83em;
  line-height: 1.84em;
  padding-bottom: .5em;
}
.app-header-search-input-field > .ant-input{
  background: transparent;
  border: none;
  font-size: 12px;
}
.app-header-links-menu-help-button {
  background: rgb(3, 174, 119);
  border: none;
  font-size: 0.83em;
  box-shadow: none;
  width: 6.1em;
  height: 2.09em;
  border-radius: 2px;
}

.app-header-description {
  height: auto;
  background: rgb(245, 242, 242);
  padding: 0.5em;
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

.app-header-description-h-25 {
  height: 25%;
  background: rgb(245, 242, 242);
  padding: 0.5em;
}

.header-description-link-href {
  color: #000;
}

.header-description-link-href:hover {
  color: rgb(102, 102, 102);
}

.header-description-link-href:active {
  color: rgb(102, 102, 102);
}

.app-header-description-text {
  color: #000;
  font-size: 0.9em;
  font-weight: bold;
}

.app-header-description-link {
  font-size: 1em;
  font-weight: 600;
  /* text-align: right; */

}
.right-header{
  font-size: 1em;
  font-weight: 600;
  text-align: right;
}
.left-header{
  font-size: 1em;
  font-weight: 400;
  text-align: left;
  margin-top: .4em;
}
/* Body(Content-Container) */
.app-body-container {
  /* height: 78.63%; */
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* margin-top: 48%; */
  /* background: #874512; */
}



.app-home-card-title {
  /* height: 20%; */
}



.ant-card {
  line-height: 1;
}

.app-home-card-container::-webkit-scrollbar {
  width: 0px !important;
}
.app-body-container::-webkit-scrollbar {
  width: 0px !important;
}





.home-card > .ant-card-cover > img {
  height: 100%;
  object-fit: fill;
}

.home-card > .ant-card-body {
  height: 55%;
  padding: 0.5em;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0em;
  font-size: 1em;
  color: rgb(51, 204, 153);
  font-weight: bold;
  height: 15px;
}
.ant-card-meta-description {
  height: 2em !important;
}

.home-card-meta {
  font-size: 0.95em;
  font-weight: 700;
  height: 4em;
  overflow: hidden;
}

.home-card-links {
  color: rgb(153, 51, 102);
  font-size: .8em;
  font-weight: bold;
  /* font-weight: bold; */
}
.ant-rate-star-full {
  color: rgb(142, 48, 95);
  } 
  .ant-rate-star-half {
    color: rgb(142, 48, 95);
    } 
  
.ant-carousel {
  height: 100% !important;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 100% !important;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

/* Administration Services */
.app-administration-services {
  height: 174px;
  background: #8E325F;
  color: rgb(245, 242, 242);
  font-weight: 300;
  margin-bottom: 2.9em;
  /* padding: 0.75em 0; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-evenly; */
}

.app-administration-services-title {
  font-size: 1.1em !important;
  padding: 0.7em 0.7em;
  font-weight: 700;
  color: #fff;
  float: left;
  width: 100%;
  text-align: center;
}



.app-administration-services-menu::-webkit-scrollbar {
  width: 0px !important;
}

.app-administration-services-links {
  display: flex;
  flex-direction: column;
  padding: 0 1.4em;
  width: 90px;
  cursor: pointer;
}

.app-administration-services-links-title {
  text-align: center;
  font-size: .8em;
  padding-top: 5px;
  font-weight: bold;
  color: #fff;
}

.app-administration-services-links-icons {
  background: #72274C;
  padding: 0.6em;
  border-radius: 50%;
  /* margin: 1.25em 0.85em; */
  text-align: center;
  justify-content: center;
  display: flex;
  /* box-shadow: 0px 5px 4px #7b7b6d; */
  /* width: 47px;
  height: 45px; */

}

.anticon-pay-circle,
.anticon-file-text,
.fa-handshake,
.fa-hospital,
.fa-graduation-cap {
  font-size: 1.2em;
}

/* Footer */



.app-footer {
  font-size: 0.83em;
  padding: 0.25em 0.83em;
}

.app-footer-links-container {
  display: flex;
  justify-content: space-between;
}

.app-footer-links {
  display: flex;
  justify-content: space-between;
  color: rgb(203, 60, 111);
}

.footer-link {
  padding: 0 0.25em;
  color: #d15486;
  font-size: 12px;
}



/* Ask Buddy */
.ask-buddy-form {
  height: -webkit-fill-available;
  overflow-y: auto;
  margin-bottom: 3em;
}

.ant-form-item-control {
  line-height: 2.5em;
}

.ant-col-xs-24.ant-form-item-label {
  line-height: 1;
}

.Ask-Buddy {
  margin: 0.5em 1em 0;
}

#Ask-Buddy > .ant-form-item {
  margin-bottom: 0.5em;
}

#Ask-Buddy > .ant-form-item > .ant-col-xs-24.ant-form-item-label {
  padding: 0;
}

/* Sider Menu */

.padding-bottom-10 {
  padding-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.site-option-list:nth-child(1) {
  padding: 20px 0px;
}

.site-option-list {
  padding: 5px 0px;
  border-bottom: 1px solid rgba(242, 255, 255, 0.1);
}

.site-option-list > li {
  list-style: none;
}

.site-option-list > li > a {
  color: #fff;
  padding: 5px 10px;
  display: block;
}

.site-avatar-username {
  padding: 0 0.75em;
  color: #fff;
}

.site-avatar-img {
  background: transparent;
  display: block;
  margin-bottom: 0.75em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100px;
  height: 100px;
}

.site-option-list > li:hover {
  background: #21c385;
}

/* STD Modal */
.ant-modal {
  top: 0;
  width: 100vw !important;
}

.ant-modal-content {
  background: #139865;
}

.ant-modal-close-x {
  color: #000;
}



@media (max-width: 767px) {
  .ant-modal {
    top: 0;
    max-width: 100vw !important;
  }
}

#STD-Modal {
  margin-top: 10%;
}
.ant-form-item {
  margin-bottom: 0;
}

#STD-Modal
  > .ant-form-item
  > .ant-col-xs-24
  > .ant-form-item-control
  > .ant-form-item-children
  > .ant-input-affix-wrapper {
  height: 3em;
}

#STD-Modal > .ant-form-item {
  margin-bottom: 1em;
}

.ant-modal-body {
  padding: 3em;
}

.std-modal-action-button {
  background: transparent;
  color: rgb(255, 255, 255);
  border: none;
  box-shadow: none;
  font-size: 1.15em;
  font-weight: 700;
}

/* Login */
.twitter-login {
  background: #fff;
  box-shadow: none;
  border: 1px solid blue;
  padding: 0.85em;
  width: -webkit-fill-available;
}

.facebook-button-class {
  width: -webkit-fill-available;
  background: #fff;
  color: #333;
  /* padding: 1em; */
  border: 1px solid rgb(76, 109, 182);
}

.facebook-button-class > i {
  float: left;
  background: rgb(76, 109, 182);
  padding: 1em;
  color: #fff;
}

.google-login {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 1em;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 0.85em;
  border: 1px solid rgb (242, 87, 61);
  width: -webkit-fill-available;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.social-media-icon-container-column
  > .ant-input-affix-wrapper
  .ant-input-prefix {
  left: -2px !important;
}

#social-icons {
  text-align: center;
  padding-bottom: 2em;
  background: #fff;
  color: rgb(51, 51, 51);
}

.social-media-icon-container-column {
  text-align: center;
  padding: 1em 3em;
}
.sign-up-header-text {
  font-size: 1.25em;
  font-weight: 600;
  color: rgb(153, 153, 153);
}

/* Tags */
.ant-tag-checkable-checked {
  background: #e23f74 !important;
  color: #fff !important;
  padding: 0.5em;
  font-weight: 600;
  border-radius: 2em;
}

.ant-tag-checkable {
  background: #f0d9e3;
  padding: 0.5em;
  font-weight: 600;
  border-radius: 2em;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #333;
}

.ant-select-selection-selected-value,
.ant-select-dropdown-menu-item {
  font-weight: 800;
  text-align: center;
  width:100%
}

.signin-input-filed {
  padding: 0 3em;
}

.app-family-card-carousel {
  position: absolute;
  top: -3.5em;
}

.app-family-card > .ant-carousel .slick-slider {
  height: 100%;
}

.app-family-card
  > .ant-carousel
  .slick-slider
  > .ant-carousel
  .slick-slider
  .slick-track,
.ant-carousel .slick-slider .slick-list {
  height: 100%;
}

.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  height: 100%;
}

.carsoule-img-container {
  width: 100%;
  height: 100%;
}

.carsoule div {
  height: 100%;
}

.family-img-cover-link {
  position: absolute;
  top: 46%;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  box-shadow: inset 2px 31px 6px 0 #ffff;
}

.family-img-cover-link-title {
  font-size: 1.5em;
  color: rgb(51, 51, 51);
}

.family-img-cover-link-text {
  color: rgb(142, 48, 95);
  font-size: 0.85em;
  font-weight: bold;
  cursor: pointer;
}

.service-container-row-reverse {
  height: 33.34%;
  display: flex;
  flex-direction: row-reverse;
  /* box-shadow: 0 3px 5px 5px #ccc; */
}



.price-badge {
  position: absolute;
  z-index: 1;
  text-align: center;
  left: 0%;
  top: 25%;
  /* width: 50%; */
  height: 50%;
  overflow: auto;

  background: #84286a;
  color: #fff;
  padding: 15px;
  border-radius: 50%;
}

.circle {
  width: 65px;
  height: 65px;
  border-radius: 90px;
  /* background-color: orange; */
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  display: table-cell;
  overflow: hidden;
  vertical-align: middle;
  display: flex;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.login-module {
  padding: 2em;
}

.pack-container {
  height: 33.3%;
  font-size: 1.15em;
}

.ant-carousel .slick-dots li button {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 5px;
}

/* About us */
.user-profile {
  /* box-shadow: 0px 0px 1px 0px #ccc; */
}

.user-profile-row-reverse {
  display: flex;
  flex-direction: row-reverse;
  /* box-shadow: 0px 0px 1px 0px #ccc; */
}

.header-button-flag {
  width: 1.5em;
  height: 21px;
  /* margin-left: 0.4em; */
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  /* border-color: transparent; */
}

.ant-input {
  padding: 0;
}
/*carousel*/

.carousel-caption {
  top: 0;
  position: absolute;
  right: 0;
  bottom: 20px;
  left: 0;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  /* box-shadow: inset 0px 76px 15px rgba(255, 255, 255, 0.6); */
}

.carousel-caption > h3 {
  margin-bottom: 0.2em;
  font-size: 1.5em;
}
.carousel-inner {
  height: 100%;
  z-index: 0;
}

.carousel {
  height: 100%;
}

.carousel-item {
  height: 100%;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.login-module-login-btn {
  background: transparent;
  border: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

/* Sign-up */
.mrb-10 {
  margin-bottom: 0.75em;
  margin-top: 1em;
}

.sign-up-title {
  font-size: 1.15em;
  text-align: center;
  font-weight: 600;
}

.expatris-desc-title {
  font-size: 0.95em;
  text-align: center;
  font-weight: 600;
}

.expatris-desc {
  font-size: 0.85em;
  text-align: center;
}

/* Icons */

.google-icon-prefix {
  font-size: 2.6em;
  color: rgb(242, 87, 61);
}

.google-icon-button {
  width: 100%;
  border: 1px solid rgb(242, 87, 61);
  /* border-radius: 0.5em; */
}

.fb-icon-prefix {
  font-size: 2.6em;
  color: rgb(76, 109, 182);
}

.fb-icon-button {
  width: 100%;
  border: 1px solid rgb(76, 109, 182);
  /* border-radius: 0.5em; */
}

.email-icon-prefix {
  font-size: 2.9em;
  color: #ffab3e;
}

.email-icon-button {
  width: 100%;
  border: 1px solid #ffab3e;
  /* border-radius: 0.5em; */
}

.options-text {
  text-align: center;
  font-size: 1em;
  font-weight: 800;
}

.login-signup-footer-container {
  margin-bottom: 0.75em;
  text-align: center;
}

.color-333 {
  color: #333;
}

/* Signin */

/* common */
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.mrb85em {
  margin-bottom: 0.85em;
  margin-top: 1em;
}

.mrb75em {
  margin-bottom: 0.75em;
}

.signup-text-option {
  font-size: 1em;
  font-weight: 800;
}

.login-with {
  font-size: 1.2em;
  text-align: center;
}

/* About us */
.about-us-service-container {
  /* height: 100%; */
}

.about-us-team-container {
  height: 50vh;
  background: #f1f1f1;
}

.about-us-team-container-title {
  height: 10%;
  font-size: 1.15em;
  padding: 0.7em;
}

.about-us-service-card {
  padding: 0 0.98em;
  margin-bottom: 0;
  color: #fff;
  height: 33%;
}

.about-us-servie-card-title {
  margin-bottom: 0;
  font-weight: 900;
  color: #fff;
  font-size: 1em;
  padding: 0.5em 0;
}

.about-us-service-card-desc {
  line-height: 1;
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 0.85em;
}



.about-us-team-card-user {
  padding: 0.85em 0 0 0.75em;
}

.about-us-team-card-user-name {
  margin-bottom: 0;
  margin-top: 0.5em;
  color: rgb(84, 193, 124);
  font-size: 1em;
  font-weight: 700;
}

.about-us-team-card-user-desc {
  font-size: 0.95em;
}

/* Ask budddy */

.send-btn {
  /* width: 100%; */
  background: rgb(142, 48, 95);
  color: #fff;
  text-align: center;
  height: 2.5em;
  width: 100%;
  text-align: center;
  /* margin-left: 21%; */
}

/* Service Package */

.service-package-card {
  height: auto;
  padding: 0.5em;
}

.service-package-card-body {
  background: #e8e8e8;
  height: 100%;
  min-height: 100px;
}

.service-package-card-body-col {
  padding: 0.5em 0 0.5em 1.25em;
}

.service-package-card-body-col-title {
  font-size: 0.95em;
}

.service-package-card-body-col-desc {
  margin-bottom: 0;
  font-size: 0.85em;
}

.service-package-card-body-col-link {
  /* float: right; */
  color: #9e519a;
  padding-right: 1em;
}

.login-module-signup-btn {
  width: 100%;
  margin: 0.75em 0;
}

#sidermenu > .ant-drawer-content-wrapper > .ant-drawer-content {
  background: #2c5617; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0b8793,
    #2c5617
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0b8793,
    #2c5617
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.app-header-search-input-field {
}

.app-header-search-input-field {
  background: transparent ;
  border: none;
  font-size: 1em;
  /* padding: 0 0.5em; */
  height: 2.09em;
  color: #fff;
}

.anticon {
  vertical-align: 0.1em;
}

.app-header-links-menu-logo > .hamberger {
  flex: 1;
}


.logo-icon{
  margin-right: 1em;
}
.app-checklist-container {
  flex: 1 0 auto;
  /* height: -webkit-fill-available; */
}

.app-checklist-body {
  padding: 1em 0.5em;
}

.app-checklist-body-title {
  margin-bottom: 1em;
  font-size: 1.5em;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5em 0.2em 0.5em;
  border-bottom: 1px solid #ccc;
}

.app-checklist-item-title {
  /* display: flex;
  justify-content: space-evenly; */
  /* flex: 2 */
}

.app-checklist-accordion {
  display: flex;
  justify-content: space-between;
}

.app-checklist-save-btn {
  float: right;
  margin-bottom: 0.75em;
}

.app-checklist-body {
  /* border-top: 1px solid #000000;
  border-bottom: 1px solid #000000; */
}

.app-checklist-item-checkbox {
  margin-right: 0.85em;
}

.app-checklist-item-icon {
  margin-left: 0.85em;
}

.about-us-avatar {
  width: 2.5em;
  height: 2.5em;
  padding: 0.5em;
  background: rgb(221, 94, 146);
}

.about-us-service-container-background {
  /* background-image: url("./Assets/Images/AboutUs/AboutUs2x.png"); */
  /* padding: 0.75em;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  box-shadow: inset 0px 0px 341px 70px #021121; */
  /* height: 65%; */
}

.about-us-footer {
  padding: 1.25em 0.83em;
  height: 8.28%;
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .app-body-container {
    /* margin-top: 2em; */
  }
  .app-administration-services-menu {
    padding: 0.3em 1em;
  }
  body,
  html {
    height: 700px;
  }
}

@media screen and (device-width: 411px) and (device-height: 823px) and (-webkit-device-pixel-ratio: 3.5) and (orientation: portrait) {
  .app-body-container {
    /* margin-top: 54%; */
  }
  .app-administration-services-menu {
    padding: 0.3em 1em;
    
  }
  body,
  html {
    height: 700px;
  }
}

.ant-empty {
  /* height: -webkit-fill-avail/able; */
}

.carousel-family-card-image {
  height: 100% !important;
  object-fit: cover;
}

.service-empty-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.main-body {
  flex: 1 0 auto;
}

.ant-result {
  flex: 1 0 auto;
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@media screen and (device-width: 360px) and (min-device-height: 550px) {
  .app-body-container {
    /* margin-top: 40%; */
  }
}

@media screen and (device-width: 360px) and (device-height: 640px) {
  .app-body-container {
    /* margin-top: 48%; */
  }
}

/* Feedback Modal and Sucess Modal */

.FeedbackModal {
  padding:  0 !important;
  width: 700px !important;
  /* height: 100vh !important; */
}
.UserReviewModal{
  width: 700px !important;
}
.FeedbackModal > .ant-modal-content >  .ant-modal-body  {
  padding: 1em !important;
  height: 100%;
}

.FeedbackModal > .ant-modal-content >  .ant-modal-footer,
.FeedbackModal > .ant-modal-content > .ant-modal-header
{
  display: none;
}
.FeedbackModal > .ant-modal-content {
  height: 100%;
}


.UserReviewModal > .ant-modal-content {
  height: 100%;
  background: #fff;
}

.UserReviewModal {
  padding:  0 !important;
  height: 100vh !important;
}

.UserReviewModal > .ant-modal-content >  .ant-modal-body  {
  padding: 1em !important;
  height: 100%;
  overflow: auto;
}

.UserReviewModal > .ant-modal-content >  .ant-modal-footer,
.UserReviewModal > .ant-modal-content > .ant-modal-header
{
  display: none;
}

.UserReviewModal > .ant-modal-content {
  height: 100%;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3em;
}

.feedback-content-container {
  /* flex: 1 0 auto; */
  display: flex;
  flex-direction: column;
}

.feedback-submit-button-container {

}

.feedback-container-img {
  padding: 2em 0;
  align-self: center;
}

.feedback-container-service-name {
  padding: 1em;
  text-align: center;
  font-size: 1.2em;
  color: #fff;
}
.ant-input:hover,.ant-input:focus {
  color: #fff;
  /* border-color: transparent; */
  /* border: none !important; */
  /* border-bottom: 1px solid #fff !important; */
}
.feeddback-container-rateing {
  align-self: center;
  padding: 2em;
}

.feedback-text-area {
  background: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    color: #fff;
    font-size: 1.15em;
    border-radius: 0px;
}
.feedback-text-area:hover {
  border-color:#fff
}

.feedback-container-textarea {
  padding: 2em 1em; 
}

.feedback-submit-btn {
  background: rgb(142, 48, 95);
  color: #fff;
  border: none;
}

.ant-btn:hover, .ant-btn:focus {
  color: #fff;
  background: rgb(142, 48, 90);
}

.sucess-modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 4em 0;
}

.sucess-result-jumbotron > .ant-result-title{
  color: #fff;
}
.city-card{
  padding: 0.7em;
}
.accomodation-btn{
  /* width: 130px; */
  text-align: center;
  padding: 5px;
  background: #388f81;
  float: right;
  color: #fff;
}
.title-name{
  position: absolute;
    z-index: 1;
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 8px;
}
.see-more{
  text-decoration: underline;
}
.cities-form{
  padding:0.6em;
}
/* .ant-select-selection
{
  background: #03ae77;
  border-color: #03ae77;
  color: #fff;
  border: none;
  box-shadow: none;
  height: 28px !important;
} */
.img-border{
  border-radius: 28px;
}
.town-area{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.town-para{
  padding: 0.3em;
}

.dummy-avtar{
  background: rgb(157, 167, 163);
  height: 50px;
  width: 50px;
  border-radius: 30px;
  margin-top: 1em;

}
.review-row{
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0 2px 8px #f0f1f2;
  padding: .4em;
  align-items: center;
  margin-bottom: 1em;
}
.city-marker{
  font-size: 1em;
  padding-right: .5em;
}
.select-subject > .ant-select-selection > .ant-select-selection__rendered >.ant-select-selection__placeholder, .ant-select-search__field__placeholder{
  /* color: #fff; */
  font-weight: bold;

}
.contact-form-row{
  margin-top: 2em;
}
.manual-tag{
  width: 130px;
  border-radius: 25px;
  margin-right: 1em;
}
.pop-confirm{
  
}
/* .ant-popover{
  left: 150px !important;
} */
.error-border{
  border: 1px solid red;
}
.lan-style{    border-right: 1px solid;
  padding-right: 4px;
  padding-left: 4px;

}
.livein{
  font-size: 11px;
}
.born-in{
  color: #33cc99;
    font-size: 12px;
}
.hobbies-border > .ant-form-item-control-wrapper{
  border: 1px solid #d9d9d9;
  padding:.4em
}
.search-box-help > .ant-select-selection{
  background: transparent;
  border: none;
  color: #fff;
}
.search-box-help > .ant-select-selection:focus{
  background: transparent;
  border: none;
  box-shadow: none;
}
.search-box-help > .ant-select-selection:active{
  background: transparent;
  border: none;
  box-shadow: none;
}
.search-box-help > .ant-select-selection{
  box-shadow: none;
}
.search-box-help > .ant-select-selection > .ant-select-arrow> .anticon{
  color: #fff;
}
.search-box-help > .ant-select-selection > .ant-select-arrow>.anticon-down{
  display: none;
}
.faq-title{
  font-weight: bold;
  padding-top: 1em;
    padding-bottom: 1em;

}
.list-faq{
  padding-left: 3em;
}
